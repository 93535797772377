
import carriers from '@/data/carriers.json';

export default {
    emits: [
        'done',
        'update:visible'
    ],
    props: {
        // 弹窗是否打开
        visible: Boolean,
        // 修改回显的数据
        data: Object
    },
    data() {
        return {
            // 国家数据
            carriers: carriers,
            // 表单数据
            form: Object.assign({}, this.data),
            // 表单验证规则
            rules: {
                name: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }],
                carrier_code: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }]
            },
            // 提交状态
            loading: false,
            // 是否是修改
            isUpdate: false,
            // API列表
            apis: [],
            // API服务
            api_services: [],
            // 显示更多选项
            showMore: false
        };
    },
    watch: {
        data() {
            if (this.data) {
                this.form = Object.assign({}, this.data);
                this.form.enabled = this.form.enabled ? '1' : '0';
                this.isUpdate = true;
            } else {
                this.form = {
                    enabled: '1'
                };
                this.isUpdate = false;
            }
            if (this.$refs.form) {
                this.$refs.form.clearValidate();
            }
        },
        'form.api_id': {
            handler() {
                this.form.api_id && this.load_api_services();
            }
        }
    },
    computed: {
        api_options() {
            return this.showMore ? this.api_services.filter(api => {
                return api.text.includes(this.form.api_service)
            }) : this.api_services
        }
    },
    mounted() {
        this.load_apis();
    },
    activated() {
        this.load_apis();
    },
    methods: {
        /**
         * 加载API列表
         */
        load_apis() {
            this.$http.get('/admin/ship_method_api/simple_list').then(res => {
                if (res.data.code === 0) {
                    this.apis = res.data.data;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        },
        /**
         * 加载API服务
         */
        load_api_services() {
            this.$http.get(`/admin/ship_method_api/services/${this.form.api_id}`).then(res => {
                if (res.data.code === 0) {
                    this.api_services = res.data.data || [];
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        },
        /* 更新visible */
        updateVisible(value) {
            this.$emit('update:visible', value);
        },
        /* 保存编辑 */
        save() {
            this.$refs.formRef.validate().then(() => {
                this.loading = true;
                this.$http.post('/admin/ship_method/update', Object.assign({}, this.form, {
                    enabled: this.form.enabled == '1'
                })).then(res => {
                    this.loading = false;
                    if (res.data.code === 0) {
                        this.$message.success(res.data.msg);
                        if (!this.isUpdate) {
                            this.form = {};
                        }
                        this.updateVisible(false);
                        this.$emit('done');
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            }).catch(() => {});
        },
        onClose() {
            this.updateVisible(false);
        }
    }
}
